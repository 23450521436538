$(function() {

    /* Trigger shad menu */
    triggerHeaderShadMenu();

    /* Open search wrapper */
    $('#trigger_recherche').on("click", function() {
        openSearchWrapper();
    });

    /* Close search wrapper */
    $('#shad_menu').on('click', function() {
        closeSearchWrapper();
    });

    /* Close search wrapper */
    $('#closeSearchWrapper').on('click', function() {
        closeSearchWrapper();
    });

    /* Trigger header products rollover from wishlist */
    triggerProductsRolloverWishlist();

    /* Toggle header products rollover selected item */
    toggleRolloverLinkItem();

    overlayScrollBarsSmallBasket();

    if($('.wrapper_tunnel.vp').length) {
        $('.wrapper_bandeau_connexion').insertBefore('.bandeau_connexion_style');
    }

    // Shift shipping delay text in basket step 1
    if($('.cart_main_title').length) {

        updateCartElements();

        // Cart slider initialization
        var sliderCart = $('#basketAssocWrapper');

        if (sliderCart.length) {

            sliderCart.insertAfter('.wrapper_panier');

            var cartSlider = new Swiper('#basketAssocSlider', {

                slidesPerView: 4,
                spaceBetween: 40,
                loop: false,
                lazy: true,
                loadPrevNext: true,
                loadOnTransitionStart: true,
                navigation: {
                    nextEl: '.nextCartAssoc',
                    prevEl: '.prevCartAssoc',
                },
            });
        }
    }

    // Delete ":" from PayPal payment title
    if($('.wrapper_bloc_banque.paypal').length) {

        $('.wrapper_bloc_banque.paypal .bank_title').text(Translator.translate('bnk_using_paypal'));
    }

    // Insert paragraph in bank right side
    if($('.bank_right_side').length) {

        var bankDeliveryReminder = '<p class="bankDeliveryReminder">'+Translator.translate('bank_delivery_reminder')+'</p>';
        $('.wrapper_payment').append(bankDeliveryReminder);
    }

    // Switch txt payment by PayPal
    if($('.wrapper_bloc_banque.paypal').length) {

        var paymentPaypalTxt = Translator.translate('by_clicking_here');
        $('.bank_subtitle').html(paymentPaypalTxt);
    }  
    
    //paypal loader
     $(".wrapper_bloc_banque.paypal").on('click touch tap', function() {
         $( this ).find( ".bank_subtitle" ).addClass( "loader_paypal" );
    })

    // Insert paragraph in bank thanks right side
    if($('.thanksRight').length) {

        $('.seeOrderEvo').insertAfter('.thanksRight');
    }

    // Insert title in login tunnel style / Change placeholder input mail
    if($('.body_login.cart').length) {

        var loginTunnelTitle = '<p class="loginTunnelTitle">'+Translator.translate('connexion')+'</p>';
        var loginTunnelPlaceholder = Translator.translate('email');
        $('.wrapper_bandeau_connexion').prepend(loginTunnelTitle);
        $('form#auth .w-email-input .w-input-label').text(loginTunnelPlaceholder);
    }

    // Custom scrollbars in relais list
    if ($('body').hasClass('step_2')) {

        window.setTimeout(function() {

            $('.sidebar-right').overlayScrollbars({});
            $('.gm-style-iw-d').overlayScrollbars({});
        }, 1000);
    }

    /********** HOMEPAGE **********/
    if ($('#sliderCoverModule .swiper-container').length) {
        var coverHomeSwiper = new Swiper('#sliderCoverModule .swiper-container', {
            preloadImages: false,
            lazy: true,
            loop: true,
            speed: 1500,
            autoplay: {
                delay: 3000,
            },
            navigation: {
                nextEl: "#sliderCoverModule .swiper-button-next",
                prevEl: "#sliderCoverModule .swiper-button-prev",
            },
        });

        $('#sliderCoverModule .swiper-container').hover(function() {
            (this).swiper.autoplay.stop();
        }, function() {
            (this).swiper.autoplay.start();
        });
    }

    if ($('#imageImageSwiper.swiper-container').length) {
        var imageImageSwiper = new Swiper('#imageImageSwiper.swiper-container', {
            preloadImages: false,
            lazy: true,
            navigation: {
                nextEl: "#imageImageSwiper .swiper-button-next",
                prevEl: "#imageImageSwiper .swiper-button-prev",
            },
        });
    }

    /* Init overlayScroll for guide des tailles */
    $('#container_guide .wrap_table').each(function() {
        $(this).overlayScrollbars({});
    });

    // Add paragraph on first step "Choix du montant" on Carte Cadeau page
    if (document.body.classList.contains('giftcard_index')) {
        addParag();
    }

    /********** RAYON **********/
    if ($('#viewChanger').length) {
        changeRayonViews();
    }

    /********** MENU GONDOLE **********/
    var menuGondoleContainer = $('.menu_gondole_container');

    if (menuGondoleContainer.length) {
        $('.header_main_wrapper .main_menu_itm').each(function() {
            var menuColumnsNumber = $('#' + this.id + ' .menu_ss_cat__list').length;
            var gondolesContainer = $('#' + this.id + ' .menu_gondole_container');

            if (menuColumnsNumber > 1) {
                gondolesContainer.addClass('oneSlidePerView');
            }
        });

        if ($('.menu_gondole_container.swiper-container .swiper-slide').length > 1) {
            var menuGondoleContainerSwiper = new Swiper('.menu_gondole_container.swiper-container', {
                slidesPerView: 'auto',
                preloadImages: false,
                lazy: true,
                navigation: {
                    nextEl: '.menu_gondole_container .swiper-button-next',
                    prevEl: '.menu_gondole_container .swiper-button-prev',
                },
            });
        }
    }

});

/********** Rayon - change views **********/
function changeRayonViews() {
    var viewChangerItems = document.querySelectorAll('#viewChanger .viewBy');
    var productsListContainer = document.querySelector('#list_item');

    for (var i = 0; i <  viewChangerItems.length; i++) {
        var item = viewChangerItems[i];

        item.addEventListener('click', function() {
            var itemId = this.id;

            for (var j = 0; j <  viewChangerItems.length; j++) {
                viewChangerItems[j].classList.remove('active');
            }

            if (!this.classList.contains('active')) {
                this.classList.add('active');
            }

            productsListContainer.className = '';

            if (!productsListContainer.classList.contains(itemId)) {
                productsListContainer.classList.add(itemId);
            }
        })
    }
}

// Update Cart elements in Cart after ajax reload
function updateCartElements() {

    var precoDeliveryDate1 = $('.cart_main_title .precoDeliveryDate');
    var precoDeliveryDate2 = $('.dropdown_cart_link .precoDeliveryDate');

    if (precoDeliveryDate1.length) {

        precoDeliveryDate1.remove();
        precoDeliveryDate2.insertAfter('.nb_products');
    } else {

        precoDeliveryDate2.insertAfter('.nb_products');
    }

    // Remove '()' in Cart article count
    var nbArticles = $('.cart_main_title.basket .nb_products').text();
    $('.cart_main_title.basket .nb_products').html(nbArticles.replace(/[()]/g, ''));

    // Remove '()' in Cart wishlist count
    var nbWishlist = $('.cart_main_title.wishlist .nb_products').text();
    $('.cart_main_title.wishlist .nb_products').html(nbWishlist.replace(/[()]/g, ''));

    // Shift basket push position step 1
    if($('.pushBasketWrapper').length) {

        $('.pushBasketWrapper').insertAfter('.cart_main_table.basket');
    }

    toggleRolloverLinkItem();

    overlayScrollBarsSmallBasket();

    /* Reset wishlist img */
    $(window).scrollTop($(window).scrollTop()+1);
}

function overlayScrollBarsSmallBasket() {
    // ROLLOVER SCROLLBARS
    if ($('#cart_list.cart_product_listing').length) {
        if ($('#cart_list .product-link').length > 2) {
            $("#cart_list.cart_product_listing ").overlayScrollbars({});
        }
    }
}

function toggleRolloverLinkItem() {
    var linkItemCart = document.querySelector('#cart_top .link_item_cart');
    var linkItemWishlist = document.querySelector('#cart_top div.link_item_wishlist');
    var rolloverProduct = document.querySelector('#cart_top');

    if (linkItemWishlist !== null) {
        linkItemWishlist.addEventListener('click', function() {
            rolloverProduct.classList.add('activeFromWishlist');
        })
    }

    if (linkItemCart !== null) {
        linkItemCart.addEventListener('click', function() {
            rolloverProduct.classList.remove('activeFromWishlist');
        })
    }
}

function triggerProductsRolloverWishlist() {
    var wishlistTriggerItem = document.querySelector('.preHeader_item.wishlist.hoverable');
    var rolloverProduct = document.querySelector('#cart_top');

    if (wishlistTriggerItem !== null) {
        wishlistTriggerItem.addEventListener('mouseenter', function() {
            rolloverProduct.classList.add('activeFromWishlist');
        })
    }
    if (rolloverProduct !== null) {
        rolloverProduct.addEventListener('mouseleave', function() {
            rolloverProduct.classList.remove('activeFromWishlist');
        })
    }
}

function triggerHeaderShadMenu() {
    var shadMenuHoverableHeaderItems = document.querySelectorAll('#site_head_wrap .hoverable');
    var shadMenu = $('#shad_menu');
    var header = $('#top_wrapper.head_top');

    for (var i = 0; i <  shadMenuHoverableHeaderItems.length; i++) {
        var hoverableItem = shadMenuHoverableHeaderItems[i];

        hoverableItem.addEventListener('mouseenter', function() {
            closeSearchWrapper();
            openMultiShad('shad_menu');

            if (this.classList.contains('entry') || this.classList.contains('submenu_wrapper')) {
                shadMenu.addClass('from_header_nav');
            } else {
                header.addClass('has_shad');
            }
        })

        hoverableItem.addEventListener('mouseleave', function() {
            closeMultiShad('shad_menu');

            if (this.classList.contains('entry') || this.classList.contains('submenu_wrapper')) {
                shadMenu.removeClass('from_header_nav');
            } else {
                header.removeClass('has_shad');
            }
        })
    }
}

function openSearchWrapper() {
    if (!$('.wrap_search_field').hasClass('actif')) {
        $('.wrap_search_field').addClass("actif");
        $('#shad_menu').addClass('actif search_active');
        $('.head_top .search').addClass('hidden');
        $('body').addClass('search_active');
    }
}

function closeSearchWrapper() {
    if ($('.wrap_search_field').hasClass('actif')) {
        $('#shad_menu').removeClass('actif search_active');
        $('.wrap_search_field.actif').removeClass("actif");
        $('.head_top .search').removeClass('hidden');
        $('body').removeClass('search_active');
    }
}

function toggleGiftCardBalance() {
    var connectBtn = $(".btn_container");

    if (connectBtn.hasClass("notLogged")) {
        location.href=path_relative_root+create_link('order_identification')+'?from=giftcard';
    } else {
        $(".pageGiftCard__main--infos").toggleClass('is-hidden');
        $(".pageGiftCard__main--amountinfos").toggleClass('is-hidden');
        $(".showAmountBtn").toggleClass('is-hidden');
    }
};

const addParag = () => {
    let step1P = document.createElement("p");
    step1P.textContent = Translator.translate('intro_gift');

    const title = document.querySelector(".selectBalanceLabel");

    title.insertAdjacentElement('afterend', step1P);
    step1P.classList.add("first");
}


/* PAGE ADV */
if ($('body.cms_category, body.cms_subcategory').length) {
    $(function(){
        $('.page_title.block_title, #cms_subcategory_menu').wrapAll('<div class="wrap_top_cms"></div>');
    });

    $('.item.see_all a').click(function() {
        $('#cms').addClass('see_all_active');
    });

    $('.item:not(".see_all") a').click(function() {
        $('#cms').removeClass('see_all_active');
    });
}

// Ajout mention sur page connexion
$(function () {
    $(".body_login #site_global_wrap .wrapper_bandeau_connexion").parent('div').addClass('vp_login_special');
    $(".vp_login_special").parent('section').addClass('vp_login_container');
});