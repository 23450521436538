/*
 * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
 * product_right.php, wishlist_index.php
 */

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;
function restoreWishlistButtonState() {
    $(".addWishlistBtn").html(button_add_to_wishlist_initial_label);
    $(".addWishlistBtn")
        .removeClass("existToWishlistButton")
        .addClass("addToWishlistButton");
    $(".addWishlistBtn")
        .attr("onclick", button_add_to_wishlist_initial_click_event)
        .attr("href", "#");
}

/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist() {

    if (!$(this).hasClass('existToWishlistButton')) {
        var btn = $(this);
        var idElt = btn.data('idelt');
        var productId = btn.data('productid');
        var productRef = btn.data('product-ref');
        var productName =  btn.data('product-name');

        if (typeof(productId) == 'undefined') {
            var productId = $("#hdn_product_id").val();
        }

        if (typeof(productName) == 'undefined') {
            var productName = $("#hdn_product_name").val();
        }

        var colorId = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
        var sizeId = $('#tailleProd_' + idElt).length == 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();
        var datas = {
            product_id: productId,
            color_id: colorId,
            size_id: sizeId,
            need_color_id: 'true',
            need_size_id: 'true'
        };
        $.ajax({
            url: path_relative_root + "ajax_add_to_wishlist.php",
            type: 'post',
            data: datas
        })
        .done(function(resp) {
            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox");
            $alertboxLogin.addClass('box_wishlist');

            if (resp.error) {

                if (resp.message == "login_required") {
                    var html =
                        '<div class="box_wishlist">' +
                        resp.result.error_message +
                        '<div class="btn_container">' +
                        '<button class="button dark" onclick="wishlistRedirectLogin();">' +
                        resp.result.btn_connexion +
                        "</button>" +
                        "</div>" +
                        "</div>";

                    if ($('#lightbox_achat_express').length > 0) {
                        $('#lightbox_achat_express').fadeOut("fast", function() {
                            $('#shad').css('display', 'none');
                        });
                    }
                    alert(html);
                    $("#abox .form_submit").css("display", "none");
                } else if (resp.result.error_message) {
                    alert(translate(resp.result.error_message));
                } else {
                    var message = resp.result.error_message ? resp.result.error_message : resp.message;
                    $(".disabledBtnTooltip").addClass('actif');
                    $(".wrap_btn_abox button span").html('ok');
                    if ($('.category').length > 0){
                        alert(Translator.translate('error_wishlist_' + message.toLowerCase()));
                    }
                    return false;
                }
            } else {

                window.dataLayer = window.dataLayer || [];
                 window.dataLayer.push({
                 "event" : "add_to_wishlist",
                 "product_name" : productName,
                 "product_id" : productId
                 });

                if (parseInt($('#in_basket').val()) === 1) {
                    var btn_wish = "#addToWishlistButton_" + product_id;
                    var span_wish = btn_wish + " span";
                    var del_product = "#del_product_" + product_id;

                    btn_wish = btn_wish.replace(',', '');
                    span_wish = span_wish.replace(',', '');
                    del_product = del_product.replace(',', '');

                    $(span_wish).html(resp.result.button_label);
                    $(btn_wish).removeClass("addToWishlistButton").addClass("existToWishlistButton");
                    $(btn_wish).attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));

                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    $(del_product)[0].click();
                } else {
                    $("#addToWishlistButton span").html(resp.result.button_label);
                    $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");
                    $("#addToWishlistButton").attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));

                    btn.addClass("existToWishlistButton");

                    wishlist.push(resp.result.wishlist_row_array);
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    changeBtnAddWishlist(true, productRef);
                }
            }
        });
    }
}

/**
 *
 * @param productId
 * @returns {boolean}
 */
 function checkProductInWishlist(productId, productRef, enableBtnWishlist = false) {
    var isProductPage = $("#produit_id").val();
    var colorId = $("#couleurProd_" + productId).val();
    var sizeId = $("#tailleProd_" + productId).val();
    if (isProductPage > 0) {
        colorId = $("#couleurProd").val();
        sizeId = $("#tailleProd").val();
    }

    if (typeof productId != "undefined" && typeof colorId != "undefined" && typeof sizeId != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + create_link("get_user_wishlist"),
            data:{'product_id': productId, 'size_id': sizeId, 'color_id': colorId},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                var wishlistButton = $("#addToWishlistButton_" + productId);
                if (isProductPage > 0) {
                    wishlistButton = $("#addToWishlistButton");
                }
                if (typeof userWishlist !== undefined && userWishlist.length > 0) {
                    changeBtnAddWishlist(true, productRef, enableBtnWishlist);
                } else {
                    changeBtnAddWishlist(false, productRef, enableBtnWishlist);
                }
            }
        });
    }
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 */
function changeBtnAddWishlist(in_wishlist, productRef, enableBtnWishlist = true) {
    var btn = $("#addToWishlistButton");

    // Default onclick Add or Remove wishlist
    var onclickAddToWishlist = "addToWishlist.call(this);";
    
    // Handle if product is in wishlist or not
    if (in_wishlist) {
        // Handle wishlist for achat express v1, onclick go to my wishlist page in another tab 
        if ($("#lightbox_achat_express").hasClass("actif")) {
            onclickAddToWishlist = "window.open('" + path_relative_root + create_link("wishlist") + "', '_blank');";
        }
        btn.val(Translator.translate("exist_to_wishlist_button_label"));
        btn.removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        btn.val(Translator.translate("add_to_wishlist"));
        btn.removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }

    // Activate btn
    if (enableBtnWishlist == true) {
        btn.attr("disabled", false);
    }
    btn.attr("onclick", onclickAddToWishlist);
}

function wishlistRedirectLogin() {
    $.get(
        path_relative_root + "ajax_add_to_wishlist.php?login_required=1",
        function() {
            document.location.href = path_relative_root + create_link("connexion_login") +"?from=front";
        }
    );
}

function addToWishlistUpdateHeader(nr) {
    $(".btn_top_wishlist .gris").html(nr);

    if (nr == 0) {
        $("#basket1").show();
        $("#basket2").hide();
        $(".btn_top_wishlist").hide();
        $("#number_wishlist_top").html("0");
    } else {
        $("#basket2").show();
        $("#basket1").hide();
        $(".btn_top_wishlist").show();
        $("#number_wishlist_top").html(nr);
    }
}

function scanForChanges() {
    var totalPrice = 0;
    totalPoints = 0;
    selectedProducts = new Array();
    $(".checkbox_select_product").each(function() {
        var element = $(this);
        var id = element.attr("data-id");

        /**
         * Sélectionne le bloc .product_ctn
         */
        var productCnt = $(this).closest('.product_ctn');

        if (element.is(":checked")) {
            var price = parseFloat(element.attr("data-price"));

            if (price) {
                totalPrice += price;
            }

            selectedProducts.push(id);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }

            /**
             * Ajoutez la classe
             * 'checked'
             */
            productCnt.addClass('checked');
        } else {
            /**
             * Retirez la classe
             * 'checked'
             */
            if(productCnt.hasClass('checked')) {
                productCnt.removeClass('checked');
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + " :";
            priceSpan.html(
                '<span id="total_price">0<span class="devise">&euro;</span><sup></sup><span class="decimal_price"></span></span>'
            );
            break;
        case 1:
            text = $texts[1];
        default:
            if (text == "") text = $texts[2];

            pricefloor = Math.floor(totalPrice);

            if (pricefloor == totalPrice) {
                decimal = "";
            } else {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = "" + decimal;
                if (decimal.length == 1) {
                    decimal = decimal + "0";
                }
            }

            priceSpan.html(
                '<span id="total_price">' +
                    pricefloor +
                    (decimal ? '<span class="decimal_price">,' + decimal + '</span>' : '') + 
                '</span>' + 
                '<span class="devise">&euro;</span> <sup></sup>'
            );

            txt =
                '<span class="wishlist_count">' +
                selectedProducts.length +
                "</span> " +
                text +
                " :";
    }

    $(".txt", "#nrArticlesSelected").html(txt);
}
function addProductToCart(wpId, pId, sId, cId) {
    $.post(
        path_relative_root + create_link("ajax_wishlist_add_product_to_cart"),
        {
            product_id: pId,
            size_id: sId,
            color_id: cId,
        },
        function(resp) {
            if (resp == "ok") {
                document.location.href =
                    path_relative_root + create_link("order_basket");
            } else {
                if ($("#ckwp_" + wpId).attr("pointscadeau")) {
                    alert(Translator.translate("err_no_points"));
                }
            }
        }
    );
}

/**
 * Ferme une popup par rapport à son nom et ferme aussi le shad
 * Utilisation : #sendwishlistbox qui est généré dinamiquement dans wishlist_index.php
 */
function closePopup(popup_id, shad_id) {
    if (shad_id == undefined) {
        shad_id = "shad";
    }
    var $popup = $("#" + popup_id),
        $shad = $("#" + shad_id);

    $shad.unbind("click.popup");
    $popup.slideUp("slow", function() {
        $shad.fadeOut("slow", function() {
            if ($("#wrapper_sendwishlist").hasClass("sended")) {
                $("#sendwishlistbox").remove();
            }
        });
    });
}

/**
 * Affiche la lighbox de la wishlist, dynamiquement si la box n'existe pas
 * Utilisation : wishlist_index.php
 */
function showsendwishlistmodal() {

    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}

/**
 * Envoie le formulaire de la lightbox wishlistavec gestion des erreurs
 * Utilisation : wishlist_index.php
 */
function sendWishListForm() {
    $("#sendWishListSubmit").css({display: "none"});
    $("#wrapper_loader_send").css({display: "block"});
    var content_id = $("#send_wishlist_ajax");
    var content;
    if (
        $("#sendWishListToMails").length &&
        $("#sendWishListToMessage").length &&
        $("#sendWishListYourName").length &&
        $("#sendWishListYourMail").length
    ) {
        var to_mails = $("#sendWishListToMails").val();
        var to_message = $("#sendWishListToMessage").val();
        var your_name = $("#sendWishListYourName").val();
        var your_mail = $("#sendWishListYourMail").val();
        var token_security = $("#token_security_wishlist").val();
        var captcha = $("#g-recaptcha-response").val();

        $.ajax({
            url:
            path_relative_root +
            create_link("ajax_send_wishlist_form") +
            "?to_mails=" +
            to_mails +
            "&to_message=" +
            to_message +
            "&your_name=" +
            your_name +
            "&your_mail=" +
            your_mail +
            "&token_security=" +
            token_security +
            "&g-recaptcha-response=" +
            captcha
        }).done(function(response) {
            $(".zone_text").css({ border: "" });

            if (response.split(",")[0] == "ok") {
                var s_adresse = response.substr(3);

                var sing =
                response.split(",").length > 2
                    ? "sendfriend_ok_plur"
                    : "sendfriend_ok_sing";

                content =
                "<div>" + Translator.translate(sing) +
                "</div>";
                content +=
                '<p style="margin-top: 10px;margin-bottom: 30px;padding:0;text-align:center;">' +
                s_adresse +
                "</p>";
            }
            if (response == "1" || response == "2") {
                content =
                '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_mail_friends_invalid') +
                "</p>";
                $("#sendWishListToMails").addClass("inputErr");
            } else {
                $("#sendWishListToMails").removeClass("inputErr");
            }
            if (response == "3") {
                content =
                '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_message') +
                "</p>";
                $("#sendWishListToMessage").addClass("inputErr");
            } else {
                $("#sendWishListToMessage").removeClass("inputErr");
            }
            if (response == "4") {
                content =
                '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_name') +
                "</p>";
                $("#sendWishListYourName").addClass("inputErr");
            } else {
                $("#sendWishListYourName").removeClass("inputErr");
            }
            if (response == "5" || response == "6") {
                $("#sendWishListYourMail").addClass("inputErr");

                if (response == "5") {
                content =
                    '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_my_mail') +
                    "</p>";
                } else {
                content =
                    '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_my_mail_invalid') +
                    "</p>";
                }
            } else {
                $("#sendWishListYourMail").removeClass("inputErr");
            }

            if (response == "token_error") {
                content =
                '<p class="txt_err_sendfriend">' + Translator.translate('error_token_security') + '</p>';
            }

            if (response.split(",")[0] == "7") {
                var s_adresse = response.substr(3);
                var sing =
                response.split(",").length > 2
                    ? "sendfriend_no_ok_plur"
                    : "sendfriend_no_ok_sing";
                content =
                "<div>" + Translator.translate(sing) + "</div>";
            }
            if (
                response.split(",")[0] == "7" ||
                response.split(",")[0] == "token_error" ||
                response.split(",")[0] == "ok"
            ) {
                content_id = $("#sendfriend_alert_mail");

                $("#sendfriend_alert_mail").css({
                display: "block",
                opacity: 0
                });
                $("#wrapper_sendwishlist")
                .addClass("sended")
                .css({ display: "block", opacity: 1 })
                .animate({ opacity: 0 }, function() {
                    $("#wrapper_sendwishlist").css({ display: "none" });
                    $("#sendfriend_alert_mail").animate(
                    { opacity: 1 },
                    function() {
                        $("#sendfriend_alert_mail").css({
                        display: "block"
                        });
                    }
                    );
                });
            } else {
                $("#sendfriend_form_buttons").removeClass("loading");
            }

            content_id.html(content).css({ display: "block" });
        });
    }

    return false;
}

//Function to load previous selected Size product in basket
function loadSelectedSize(cnt_sizes) {

    var sizes = document.getElementsByName('itm_size');

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link("ajax_size_selected"),
        success: function (response) {
            if (response && response.length > 0) {
                response = $.parseJSON(response);
                var selected_size = (response.selected_size);
                var current_size;
                var current_element;
                var sizeProd = document.getElementById("tailleProd");
                var html = '';

                for (var i = 0; i < sizes.length; i++) {
                    current_element = sizes.item(i).value;
                    if ((selected_size === current_element && current_element !== null) && (!sizes.item(i).classList.contains("disabled"))) {
                        sizes.item(i).checked = true;
                        sizeProd.value = current_element;
                        enableAddCart();
                    }
                }
            }
        }
    });
}

function moveProductFromWishlistToCart(array_wishlist_product_ids) {
    $.post(path_relative_root + "ajax_add_panier_wishlist.php", {data: array_wishlist_product_ids.join(",")}, function(resp) {
        if (resp == "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

/**
 * Allows you to add a product to the wishlist via the ray (or search)
 * app/_components/product/achat_express_search.php
 * app/_components/product/achat_express.php
 * If user is connect, display wishlist lightbox (achat express wishlist = true)
 * if not, call the product page addToWishlist function which already display a connect lightbox 
 */
function addToWishlistRay(user) {
    if (user) {
        var btn = $(this);
        var productId = btn.data('productid');
        var productRef = btn.data('product-ref');
        ajax_achat_express(productRef, true, productId, true);
    } else {
        addToWishlist.call(this);
    }

    setTimeout(function() {
        if ($(".page_produit .product_info_wrapper").length) {
            $(".product_eclat_attributes").appendTo(".price_and_wishlist .item_price");
        }
    }, 700);

    setTimeout(function() {
        if ($(".page_produit .product_info_wrapper").length) {
            $(".product_eclat_attributes").addClass('visible');
        }
    }, 1000);
}

function remove_product_from_wishlist(id, callback) {
    $.post(path_relative_root + create_link("ajax_remove_from_wishlist"), { id: id }, callback);
}

function ajax_RemoveWishlistProd(id) {
    remove_product_from_wishlist(id, function(data) {
        if (data.result) {
            var wishlistLength = data.result.wishlistLength;
            addToWishlistUpdateHeader(wishlistLength);
            $('.remove_prod.wishlist_remove[data-id=' + id + ']').parents('.product-link').remove();
            if (wishlistLength < 1) {
                $('#wishlist_top .title_panier').remove();
                $('#wishlist_top .wrap_tot_panier').remove();
                $('#wishlist_top #cart_list').remove();
                $('#wishlist_top #show_top_cart').addClass('noprod');
                var newtxt = '<div class="no_product">' + Translator.translate('your_wishlist_empty');
                $('#wishlist_top #show_top_cart').append(newtxt);
            } else {
                $('#wishlist_top .title_panier .title_wishlist_rollover .nb_products').text('(' + (wishlistLength));
                $("#wishlist_top .cart_product_listing").overlayScrollbars({});

            }
        }
    });
}