function addLook(loader) {
    $(loader).fadeIn(200);

    look_add = true;

    calculTotal("slide_look_detail", true);
}

function calculTotal(id, process_look) {
    var $form = $(document.getElementById(id));
    var data = $form.serialize();

    $.ajax({
        type: "post",
        url: path_relative_root + "ajax_calcul_total.php",
        data: data,
        success: function(response) {
            try {
                response = JSON.parse(response);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            if (response.success == "ok") {
                var datas = response.datas;
                var total = datas.total;

                $("#look_total", "#look_info").val(total);
                $(".price_total", "#look_info").html(datas.total_format);

                if (process_look) {
                    if (total > 0) {

                        buildLookAddedModbox(false);
                        processlook();
                    } else {
                        var $alertbox = $("#alertbox1");

                        res = Translator.translate('js_error_quantity');

                        html_err =
                            '<div class="txt_alert"></div><div class="rosette"></div>';
                        html_err +=
                            '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
                        if ($alertbox.length == 0) {
                            $alertbox = $(
                                '<div id="alertbox1">' + html_err + "</div>"
                            );
                            $("body").append($alertbox.hide());
                        }

                        $alertbox.find(".txt_alert").html(res);
                        openMultiShad("alertbox1");

                        $("#bloc_btn_look").show();
                        $("#bloc_btn_loader_look").hide();

                        if ($(".loader", "#bloc_add_basket").length > 0) {
                            $(".loader", "#bloc_add_basket").hide();
                        }
                    }
                }
            }
        },
    });
}

function buildLookAddedModbox(is_not_produit) {

    var form = document.getElementById('slide_look_detail');
    var closing_method = (is_not_produit == 'true') ? 'window.location.reload()' : 'close_lightbox(\'modbox\')';
    var look_total = parseFloat(document.getElementById('look_total').value);
    var look_items = [];

    $('fieldset', form).each(function () {

        var itm_quantity = parseFloat($('.qte_select_wrapper select', this).get(0).value);

        if (itm_quantity > 0) {

            var new_look_itm = {};

            new_look_itm.itm_visu = $('.visu_look', this).attr('src').replace('/medium/', '/mini/');
            new_look_itm.itm_name = this.getValue('itm_name');
            new_look_itm.itm_quantity = itm_quantity;
            new_look_itm.itm_total = this.getValue('itm_price', parseFloat) * itm_quantity;

            look_items.push(new_look_itm);
        }
    });

    $('#shade').after($('#modbox'));
}

function processlook() {
    $(".submit_panier").each(function() {
        var $elem = $(this).attr("id");
        var $id = $elem.substring($elem.lastIndexOf("_") + 1);
        var $val_item = $("#qteProd_" + $id).val();

        if ($val_item > 0) {
            $("#look_add_lightbox_" + $id).show();
            $(this).click();
        } else {
            $("#look_add_lightbox_" + $id).hide();
        }
    });

    setTimeout(showsendfriendmodal, 1000, 0, path_relative_root, "look_basket");
}

function waitingAddToBasket_legacy(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, is_lightbox_specifique) {

    var id = '';
    if( is_not_produit == true )
        id += '_' + produit_id;

    if ( is_lightbox_specifique )
        add_produit_specifique(produit_id, idCouleur, idTaille);
    else
        window.setTimeout('addToBasket_legacy("'+produit_id+'", "'+suff+'", "'+panier_id+'", "'+titreObjet+'", "'+idTaille+'", "'+lastTaille+'", "'+idCouleur+'", "'+lastCouleur+'","' + toEval + '", "'+page_name+'", "'+path_web+'", '+enable_omniture+', '+is_not_produit+');',1000);

    $("#bloc_btn_active"+id).attr('class','cache');
    $("#bloc_btn_loader"+id).attr('class','f_right');

}

function addToBasket_legacy(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit) {

    var id = '';

    if( is_not_produit == true )
        id += '_' + produit_id;

    prix_id = 'prixU';
    if( is_not_produit == true ) {
        var code_color = $('#couleurUnique_' + produit_id).text();
        var nom_color = $('#couleurUnique_' + produit_id).text();
        var prix_id = 'prixU_'+produit_id;
    }

    var array_panier = newUpdatePanier_legacy(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false)
    {
        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0)
        {
            type = 'err';
        }

        omnitureEvent = '';
        var response = ajax_file( path_web + 'ajax_show_basket.php');
        $("#cart_top").html(response);

        if (array_panier[1] === 0)
        {
            $('#nb_article').html(array_panier[1]);
            omnitureEvent = '';
        }
        else if (array_panier[1] == 1)
        {
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web+create_link('order_basket')).attr('style', 'cursor:pointer;');

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        }
        else
        {
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web+create_link('order_basket')).attr('style', 'cursor:pointer;');

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        newshowmodal(array_panier[1], type, path_web, is_not_produit, produit_id);

        $("#bloc_btn_active"+id).attr('class','');
        $("#bloc_btn_loader"+id).attr('class','f_right cache');

        if (array_panier[1] > 0 && enable_omniture == true)
        {
            void(s.t());
            s.pageName  = "'" + page_name + "'";
            s.products  = ";" + produit_id;
            s.events    = omnitureEvent;
            void(s.t());
        }
    }
    else {

        $("#bloc_btn_active"+id).attr('class','');
        $("#bloc_btn_loader"+id).attr('class','f_right cache');

    }

    eval(toEval);

    if (array_panier != false)
    {
        if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 )
        {
            var svn=document.getElementsByTagName("SELECT");
            for (a=0;a<svn.length;a++)
            {
                svn[a].style.visibility="hidden";
            }
        }
    }

    return false;
}

var old_onclick_shad = 'popup_confirm_reinitialisation_mdp';

function show_lightbox(popup_confirm_reinitialisation_mdp) {
    $('#shad_popup_confirm_reinitialisation_mdp').fadeTo("slow", 0.8).queue(function() {
        $(this).css('display', 'block');
        $('#' + popup_confirm_reinitialisation_mdp).slideDown("slow");
        $(this).dequeue();
    });
    $("#shad_popup_confirm_reinitialisation_mdp").click(function() {

        close_lightbox(popup_confirm_reinitialisation_mdp);

    });
}

function newUpdatePanier_legacy(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur){
    if (i != '')
    {
        i = '_'+i;

        prixU = $('#prixU'+i).val();
    }

    var couleurs = document.getElementById('couleurProd'+i);
    var tailles = document.getElementById('tailleProd'+i);
    var $alertbox = $( '#alertbox1' );
    var res = '';
    var html_err = '';
    var flagError = false;
    if($('#lightbox_achat_express').length>0){
        from_range=true;
    }else{
        from_range=false;
    }

    html_err = '<div class="txt_alert"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="button primary">OK</a></div>';
    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox1">'+ html_err +'</div>');
        $('body').append($alertbox.hide());
    }

    if(tailles && tailles.value == '') {
        res = Translator.translate('js_error_size'); 
        flagError = true;

    } else if(couleurs && couleurs.value == '') {
        res = Translator.translate('js_error_couleur');
        flagError = true;

    } else if (document.getElementById('qteProd'+i) && document.getElementById('qteProd'+i).value == '') {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError)
    {
        $alertbox.find('.txt_alert').html(res);
        openMultiShad('alertbox1', 'fixed');
        return false;
    }
    else {

        var qte = 0;

        if ($('#qteProd'+i))
        {
            qte = $('#qteProd'+i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.value != 'none') idCouleur = couleurs.value;
        if (tailles && tailles.value != 'none') idTaille = tailles.value;

        var array_response = new Array();

        var response = ajax_file(path_web+create_link('ajax_update_basket')+'?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId); 

        if (response) {

            array_response = response.split("//");

            var designation = array_response[0];

            if ( titreObjet.match('^[0-9]') ) {

                if ( designation == 1 ) {

                    designation += ' lot de';

                } else {

                    designation += ' lots de';

                }

            }

            designation += ' ' + titreObjet;

            if ( document.getElementById(idElTaille) ) {

                if ( document.getElementById(idElTaille).selectedIndex ) {
                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;


                } else if ( lastTaille != '' ) {

                    designation += ' - Taille ' + lastTaille;

                }

            }

            if ( document.getElementById(idElCouleur) ) {

                if ( document.getElementById(idElCouleur).selectedIndex ) {
                    designation += ' - ' + document.getElementById(idElCouleur).options[document.getElementById(idElCouleur).selectedIndex].text;

                } else if ( lastCouleur != '' ) {

                    designation += ' - ' + lastCouleur;

                }

            }

            array_response[0] = designation;

        }

        return array_response;
    }
}