$(function () {

    var siteHeader = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {
        if (!document.body.classList.contains("in_tunnel")) {
            var scrollTop = $(document).scrollTop();

            if (siteHeader.classList.contains("sticky")) {
                if (scrollTop === 0) {
                    siteHeader.classList.remove("sticky");
                    shade.classList.remove("sticky");
                }
            } else {
                if (scrollTop !== 0) {
                    siteHeader.classList.add("sticky");
                    shade.classList.add("sticky");
                }
            }
        }
    }

    function onSearchStateChange(evt) {
        if (evt.type === "focus") {
            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {
            search.parentElement.classList.remove("searching");
        }
    }

    $(window).on("scroll", onWinScroll);
    $(search).on({ focus: onSearchStateChange, blur: onSearchStateChange });

    onWinScroll();

    // Pre header swiper
    if ($('.promo_swiper').length && ($('.promo_swiper .swiper-slide').length > 1)) {
        var preheader_swiper = new Swiper('.promo_swiper', {
            autoplay: true,
            slidesPerView: 1,
            loop: true,
            speed: 2000,

            navigation: {
                nextEl: '#header_mention_id .swiper-button-next',
                prevEl: '#header_mention_id .swiper-button-prev',
            },
        })
    }

    $(document).on("click", ".button", btnLoaderTrigger);
});

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

// Buttons loader management
function btnLoaderTrigger(evt) {
    "use strict";

    var element = $(this);
    var loader = $(this).next(".loader");

    if (loader.length > 0) {

        evt.preventDefault();

        loader.removeAttr("style").parent().addClass("loading");
        loader.removeClass("cache");
        loader.addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
            loader.addClass("cache");
            loader.removeClass("loading");
        }, 1200);

        if ($(this).attr("href")) {
            window.location.assign($(this).attr("href"));
        }
    }
}

function openMultiShad(id) {
    if ($('.lightbox.actif').length) {
        $('.lightbox.actif').removeClass('actif');
    }

    $("#" + id).addClass('actif');

    if (id !== 'zoomBox') {
        if (!$("#shad").hasClass('actif')) {
            $("#shad").addClass('actif');
        }
        $("#shad").on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id == "lightbox_achat_express") {
        var prod_id,
            form,
            prod_qty,
            prod_rr_id,
            prod_is_wish;

        prod_id = $('#lightbox_achat_express #produit_id').val();
    }

    if (id === "zoomBox") {

        $('#zoom_box_wrapper .img_large').on('click', function () {

            closeMultiShad('zoomBox');
        });
    }

    if (id === 'popup_savoir_plus') {
        $("#popup_savoir_plus").css("display", "block");
        $('#shad').css('display', 'block');
    }

    if (id === 'lightboxOrderGiftCard' || id === 'sendwishlistbox') {
        $('#shad').addClass('actif');
    }

    if (id === 'lightboxOrderGiftCard') {
        $('#shad').css('z-index', '11');
    }

    if (id === 'e-resaBox') {
        $('#shad').addClass('actif_eresa');
    }
}

function closeMultiShad(id) {
    $('body').css('position', '');
    $("#" + id).removeClass('actif');
    $("#shad").removeClass('actif');
    $(".shad").removeClass('actif').removeClass('white');
    $('#shad').removeClass('actif_eresa');

    if (id === 'lightboxOrderGiftCard') {
        $('#shad').css('z-index', '10');
    }
}

function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + "ajax_get_uri_by_lang.php",
        type: "post",
        data: { lang: locale, url: uri },
        success: function (data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        },
    });
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function () {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

// Checks any form
function ajax_checkAllForm(tabError, blockMenuTopConnexionInfobulle, path_relative_root) {

    if (checkAllForm(tabError, blockMenuTopConnexionInfobulle)) {

        if (tabError[0][1] && tabError[0][1] == 'clientMailAuthTop') {

            $.ajax({
                url : path_relative_root + 'ajax_login_allowed.php',
                type : 'get',
                data : {
                    mail : document.getElementById(tabError[0][1]).value,
                    mdp : document.getElementById(tabError[1][1]).value,
                    is_top : true
                },
                success : function (res) {

                    var url, index_deb, index_fin, provenance;

                    if (res[0] !== '0') {

                        url = window.location.pathname;
                        index_deb = url.lastIndexOf('/');
                        index_fin = url.lastIndexOf('.');
                        provenance = url.substring(index_deb + 1, index_fin);

                        if (provenance === 'connexion') {

                            window.location.replace(path_relative_root + create_link('client_index'));
                        } else if (provenance === 'identification') {

                            window.location.replace(path_relative_root + 'livraison-kiala.php');
                        } else {

                            window.location.reload();
                        }
                    } else {

                        document.getElementById('AuthTopErr').style.display = 'block';

                        $('.loader').css('display', 'none');
                    }
                }
            });
        } else if (tabError[0][1] && tabError[0][1] == 'mailLostTop') {

            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url : path_relative_root + 'ajax_email_exist.php',
                type : 'get',
                data : { mail : mail },
                success : function (res) {

                    if (res == 1) {

                        $.ajax({
                            url : path_relative_root + 'ajax_send_new_password_link.php',
                            type : 'get',
                            data : { mail : mail },
                            succes : function () {

                                show_lightbox('popup_confirm_reinitialisation_mdp');

                                document.getElementById('lostpwdTopErr').style.display = 'none';
                                document.getElementById('lostpwdTopSuccesTitle').style.visibility = 'hidden';

                                hideLoader();
                            }
                        });
                    } else {

                        document.getElementById('lostpwdTopErr').style.display = 'block';

                        hideLoader();
                    }
                }
            });
        } else if (tabError[0][1] && tabError[0][1] == 'clientMailAuth') {

            $.ajax({
                url : path_relative_root +'ajax_login_allowed.php',
                type : 'get',
                data : {
                    mail : document.getElementById(tabError[0][1]).value,
                    mdp : document.getElementById(tabError[1][1]).value,
                    is_top : false
                },
                success : function (res) {

                    hideLoader();

                    if (res == 1) {

                        $('#auth').submit();

                        return true;
                    } else {

                        document.getElementById('AuthErrErr').style.display = 'block';

                        return false;
                    }
                }
            });
        } else if (tabError[0][1] && tabError[0][1] == 'mailLost') {

            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url : path_relative_root + 'ajax_email_exist.php',
                type : 'get',
                data : { mail : mail },
                success : function (res) {

                    if (res == 1) {

                        $.ajax({
                            url : path_relative_root+ 'ajax_send_new_password_link.php',
                            type : 'get',
                            data : { mail : mail },
                            success : function (res) {

                                show_lightbox('popup_confirm_reinitialisation_mdp');

                                document.getElementById('lostpwdErr').style.display = 'none';
                                document.getElementById('lostpwdSuccesTitle').style.visibility = 'hidden';

                                hideLoader();

                                return false;
                            }
                        });
                    } else {

                        document.getElementById('lostpwdErr').style.display = 'block';

                        hideLoader();

                        return false;
                    }
                }
            });
        }
    } else {

        $('.loader').css('display', 'none');
    }
}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id) {

    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;
    if (this.elements.namedItem('qteProd')) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v1 = $('.express_response').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id: old_id,
        produit_principal: old_id,
        couleurProd: col_id,
        tailleProd: sze_id,
        act: act,
        qteProd: qte_itm,
        hdn_product_id: old_id,
        hdn_is_model: is_model,
        produit_id_new: new_id,
        type: type,
        type_page: (page !== undefined && page !== '') ? page : 'product',
        is_achat_express: (is_wishlist || is_achat_express_v1) ? 1 : 0,
        is_achat_express_v2: is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod: cpt_prod - 1
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        success: function (res) {

            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {

                var datas = res.datas;

                if (data.type_page == 'product') {

                    var $html_main = $(stripCombo(datas.html_main));

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    } else if (is_wishlist || is_achat_express_v1) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());

                        if ($("#buyNowSwiper").length > 0) {
                            var buyNowSwiper = document.getElementById("buyNowSwiper");

                            if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {

                                buyNowSwiper = new Swiper("#buyNowSwiper", {
                                    sliderPerView: 1,
                                    nextButton: '.swiper-button-next',
                                    prevButton: '.swiper-button-prev'
                                });
                            }
                        }

                        if (!$(".w-alertestock-form").length) {
                            new Vue({ components: { wAlertestockForm: wAlertestockForm } }).$mount('#keep_alive_stock');
                        }

                    } else {


                        var $html_middle = $(stripCombo(datas.html_middle));

                        var color = $("#ligne_couleur").html();

                        var taille = $("#ligne_pointure").html();

                        var contents = $('<div />').html($html_main);

                        // Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                        var video = $('.wrapper_video', contents);

                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);

                        // Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());

                        // On scroll en haut de la page dans le cas d'un regroupement
                        $('html, body').animate({
                            scrollTop: 0
                        }, 500);
                    }
                } else if (data.type_page == 'assoc') {
                    if (data.is_achat_express_v2) {

                        // Photo et information
                        var $html_main = $(stripCombo(datas.html_main));
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    }
                }

                json_colisages = res.stock;
                // On definie les tailles qui ont encore du stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                var productSwiper = new Swiper(".productSwiper", {
                    slidesPerView: 1,
                    observer: true,
                    observeParents: true,
                    navigation: {
                        nextEl: '.rollover_rayonsNext',
                        prevEl: '.rollover_rayonsPrev',
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

                /** Init description first elem */
                loadReadMoreButton('#description .detail_content', '.see_more', 10, '.thumbcnt');

                $('.thumbslide .see_more').on('click', function (e) {
                    $('.thumbslide').removeClass('active');
                    $(this).parents('.thumbslide').addClass('active');

                    if (!$(this).parents('.thumbslide').hasClass('clicked')) {
                        $(this).parents('.thumbslide').addClass('clicked');
                    }
                });

            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {

            var heads = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function (event) {
                event.preventDefault();

                var element = $(this);
                var tab_number = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {
                if ($("#visual_scroller .swiper-slide").length > 1) {
                    var productThumbs = new Swiper('#visual_scroller', {
                        slidesPerView: 'auto',
                        spaceBetween: 22,
                        direction: 'vertical',
                        freeMode: true,
                        loop: false,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                        navigation: {
                            nextEl: '.thumbNext',
                            prevEl: '.thumbPrev',
                        },
                    });
                } else {
                    $("#visual_scroller_wrapper").addClass('no_swiper');
                }

                // PRODUCT SWIPER
                if ($("#jqzoom .swiper-slide").length > 1) {
                    //animation to avoid slide's width transition on pageload
                    $('#jqzoom').animate({
                        opacity: 1,
                    }, 10);
                    var main_productVisu = new Swiper('#jqzoom', {
                        loadOnTransitionStart: true,
                        thumbs: {
                            swiper: productThumbs
                        },
                        slidesPerView: 1,
                        loop: false,
                    });
                } else {
                    $('#jqzoom').animate({
                        opacity: 1,
                    }, 10);
                }

                /**
                 * Init zoom thumbs swiper
                 */
                if ($("#thumbs_scroller .swiper-slide").length > 1) {
                    var zoomThumbs = new Swiper('#thumbs_scroller', {
                        slidesPerView: 'auto',
                        spaceBetween: 24,
                        direction: "vertical",
                        loop: true,
                        navigation: {
                            nextEl: '.thumbsNext',
                            prevEl: '.thumbsPrev',
                        },
                    });
                } else {
                    $("#thumbs_scrolle, .thumb_wrapper").addClass('no_swiper')
                }

                /*COLORIS FP*/
                if ($("#colorsSwiperFp .swiper-slide").length > 1) {
                    var coloris_fp = new Swiper('#colorsSwiperFp', {
                        slidesPerView: 8.45,
                        spaceBetween: 24,
                        loop: false,
                        slidesOffsetAfter: 20,
                        navigation: {
                            nextEl: '.coloris_next',
                            prevEl: '.coloris_prev',
                        },
                        breakpoints: {
                            1024: {
                              spaceBetween: 16,
                            },
                            1540: {
                              spaceBetween: 24,
                            }
                        }
                    });
                } else {
                    $("#colorsSwiperFp").addClass('no_swiper')
                }

                /**
                 * Init zoom main swiper
                 */
                if ($("#zoom_box_wrapper .swiper-slide").length > 1) {
                    var main_productVisu = new Swiper('#zoom_box_wrapper', {
                        slidesPerView: 1,
                        mousewheel: true,
                        thumbs: {
                            swiper: zoomThumbs
                        },
                        preloadImages: false,
                        lazy: true,
                        loadPrevNext: true,
                        loadOnTransitionStart: true
                    });
                } else {
                    $("#zoom_box_wrapper").addClass('no_swiper')
                }

                /**
                 * Zoom product img
                 */
                function zoomImg(slide) {

                    $('#zoom_box_wrapper').css('opacity', 0);
                    window.setTimeout(function () {
                        if (slide) {
                            goto = slide;
                        }
                        else {
                        }

                        goto = goto + 1;
                    }, 50);

                    window.setTimeout(function () {
                        $('#zoom_box_wrapper').css('opacity', 1);
                    }, 350);

                    openMultiShad('zoomBox');
                    $("#shad_abox").addClass('actif white');
                    $('body.product_page').css('position', 'fixed');

                    if (main_productVisu) {
                        main_productVisu.slideTo(slide - 1);
                    }
                }

                function updateSrc(path_web, dest) {
                    var tmp_string_large = document.getElementById("img_large").src;
                    tmp_string = tmp_string_large.replace(/medium/, "large");

                    document
                        .getElementById("img_large")
                        .setAttribute(
                            "onclick",
                            "zoomImg('" + tmp_string + "','" + path_web + "')"
                        );
                    document.getElementById("loupe").href =
                        "javascript:zoomImg('" + tmp_string + "','" + path_web + "');";
                    $(".zoomLens").css({ backgroundImage: 'url("' + tmp_string_large + '")' });

                    if (dest == "vue") {
                        var i = 0;
                        var trouve = false;
                        while (i < tab_js.length && !trouve) {
                            trouve =
                                tab_js[i][0].substring(tab_js[i][0].lastIndexOf("/") + 1) ==
                                tmp_string.substring(tmp_string.lastIndexOf("/") + 1);
                            i++;
                        }

                        cpt_img = i - 1;
                    }
                }

                function updateZoomedImg(src) {
                    $(".main_vis img", this).attr("src", src);
                }

                /* Fin reinitialisation sliders visus */

                var more_vis = $('.more_vis');
                var grabber;

                more_vis.each(function () {

                    var jqzoom = $(this).next('#jqzoom');

                    if (jqzoom.length > 0) {

                        $(this).height(jqzoom.height());

                        if (document.getElementById('visual_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('visual_scroller'), true, false);
                            }, 250);
                        }
                    }

                    var zoom_box_wrapper = $(this).next('#zoom_box_wrapper');

                    if (zoom_box_wrapper.length > 0) {

                        $(this).height(zoom_box_wrapper.height());

                        if (document.getElementById('thumbs_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('thumbs_scroller'), true, false);
                            }, 250);
                        }
                    }

                });

                // Resetting alert stock field
                $('.bloc_add_alert_form').hide();

                var productImgsWrapper = $(".productImgsWrapper");
                var productDescrWrapper = $(".productDescrWrapper");

                $(document).on("scroll", function () {

                    var scrollTop = $(document).scrollTop();
                    var newTop = (scrollTop - (productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()));

                    if (scrollTop >= productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()) {

                        productDescrWrapper.css("top", "-" + newTop + "px");
                    } else {

                        productDescrWrapper.css("top", "auto");
                    }
                });

                $(window).on('resize', function () {

                    more_vis.each(function () {

                        var jqzoom = $(this).next('#jqzoom');

                        if (jqzoom.length > 0) {

                            $(this).height(jqzoom.height());
                        }

                        var jqzoom = $(this).next('#zoom_box_wrapper');

                        if (jqzoom.length > 0) {

                            $(this).height(zoom_box_wrapper.height());
                        }

                    });
                });

                setTimeout(function () {
                    checkSubmitState();
                    eventButton();
                }, 500);
            }, 250);

            heads.each(function () {
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
            var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

            getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd, '', data.couleurProd, itm_rr_id, is_wishlist);

            $('#addToWishlistButton', '#site_global_wrap').on('click', addToWishlist);

            checkProductInWishlist(data.produit_id_new, itm_rr_id);

            if (is_wishlist) {
                // event sur le bouton d'ajout à la wishlist
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            if ($(".productSwiper").length) {
                var productSwiper = new Swiper(".productSwiper", {
                    slidesPerView: 1,
                    observer: true,
                    observeParents: true,
                    navigation: {
                        nextEl: '.rollover_rayonsNext',
                        prevEl: '.rollover_rayonsPrev',
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });
            }

            //Simulates scroll (2px) to load images
            window.scrollTo(0, $(window).scrollTop() + 2);
            if ($("body").hasClass("product_page")) {
                $(".product_eclat_attributes").appendTo(".price_and_wishlist .item_price");
                setTimeout(function () {
                    $(".product_eclat_attributes").addClass('visible');
                }, 100);
            }
        }
    });
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {

    var control, prod_id, from_page;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {

        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        var is_assoc_product = form.getValue('isAssocProduct') ? form.getValue('isAssocProduct') : 0;

        if (is_assoc_product) {
            from_page = 'assoc';
        } else {
            from_page = 'product';
        }

        if (is_regroup) {

            loadProduct.call(form, prod_id, 'couleur', from_page, itm_rr_id);
        } else {

            // On regarde si le produit est dejà dans la wishlist
            checkProductInWishlist(prod_id, itm_rr_id);

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

/**
 * @param product_id : l'id du produit
 * @param regroup_ref_id : l'id du regroupement (si exist)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    $('#ligne_pointure_' + product_id + regroup_ref_id).show();
    $('#ligne_couleur_' + product_id + regroup_ref_id).hide();
    $("#prod_info_" + product_id + regroup_ref_id + " .bloc_add_color").hide();
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var control;
    var isWishList = $('#wishlist').length > 0;
    var isAchatExpressV2 = ($('#is_achat_express_v2').val() == 1 && !isWishList);
    var productId = form.getValue('produit_id');
    var elem = isAchatExpressV2 ? '_' + productId + itm_rr_id : '';
    var productRef = form.getValue('produit_ref');
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();
    var from = ($('#is_from_lookbook').val() !== undefined) ? 'lookbook' : 'product';
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });
            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        // Depending on the color we see if the product is available, do this only in product page
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), from, event, itm_rr_id, isWishList, productRef);

        // We check if the product is already in the wishlist
        if (!isAchatExpressV2) {
            checkProductInWishlist(productId, productRef, true);
        }
    }
    //On active le cta reservation en magasin
    $('#btn_resa_shop').removeAttr('disabled');
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var color_id = $('#couleurProd' + elem).val();
    var size_id = $('#tailleProd' + elem).val();
    var plnb = form.getValue('produit_lot_nb');

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }

    $('#sizeerror').addClass('hided');

    var data = {
        idprod: form.getValue('produit_id'),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from == 'basket') {

        data.suff = form.getValue('produit_id');
    }

    if (plnb) {

        data.produit_lot_nb = plnb;
    }
    $.ajax({
        url: path_relative_root + create_link('ajax_liste_quantity'),
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            // Check if globale delivery country
            // OR is express checkout AND have no warehouse stock AND product not SFS
            if (res.disable_addtocart == true && ((typeof GEP != 'undefined' && GEP.isOperatedByGlobale !== undefined && GEP.isOperatedByGlobale !== false)
                || (is_achat_express && (typeof GEP == 'undefined' && typeof res.is_sfs !== "undefined" && res.is_sfs == false)))) {
                res.stock_tampon = 0;
            }

            if (res.stock_tampon == 0) {
                $(".eclat_product_nouveaute_fiche_produit").show();

                $("#bloc_add_alert" + elem).show();
                $("#prod_info" + elem + " .rollover_left").hide();

                if (!is_achat_express && is_wishlist == false) {
                    $("#bloc_add_basket").hide();
                    if (!$(".w-alertestock-form").length) {
                        new Vue({ components: { wAlertestockForm: wAlertestockForm } }).$mount('#keep_alive_stock');
                    }
                }

                $('#ligne_qte').append($('.restock_date'));
                $('.taunt_text').css('display', 'none');
            } else {
                if (res.stock_tampon > 0 && res.stock_tampon <= 5) {
                    if (res.stock_tampon == 1) {
                        $('.taunt_text').html(Translator.translate('last_taunt', res.stock_tampon));
                    } else {
                        $('.taunt_text').html(Translator.translate('last_taunts', res.stock_tampon));
                    }
                    $('.taunt_text').css('display', 'block');
                } else {
                    $('.taunt_text').css('display', 'none');
                }

                if (is_achat_express) {
                    waitingAddToBasket(event, form, '', itm_rr_id);

                    if (from == 'product') {
                        // Show bloc colors when we add product to basket
                        $('#ligne_pointure' + elem).hide();
                        $('#ligne_couleur' + elem).show();
                        $("#prod_info" + elem + " .bloc_add_color").show();
                    } else if (from == 'lookbook') {
                        closeMultiShad('lightbox_achat_express_look');
                    }

                } else {
                    $(".eclat_product_nouveaute_fiche_produit").hide();
                    $("#bloc_add_alert").hide();
                    $("#bloc_add_basket").show();
                }
            };

            // Check if globale delivery country
            if (res.disable_addtocart == true) {
                if ((typeof GEP != 'undefined' && GEP.isOperatedByGlobale !== undefined && GEP.isOperatedByGlobale !== false) || (typeof GEP == 'undefined' && typeof res.is_sfs !== "undefined" && res.is_sfs == false)) {
                    $("#bloc_add_basket").hide();
                    $("#bloc_add_alert").show();
                    $(".product_main_wrapper #size_" + size_id).addClass('disabled');
                }
            }
        }
    });
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {

    var id = '';

    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true || is_achat_express) {

        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {

        if (from == 'basket') {

            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {

            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {

            loader.removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void (s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void (s.t());
        }

        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            pushIntoDatalayer(array_panier[5]);
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + create_link('ajax_show_basket'));

        $("#cart_top").html(response_basket);

        var count_bask = $('#cart_top .cart').attr('data-cart');

        if (count_bask > 2) {
            $("#cart_top .cart_product_listing").overlayScrollbars({});
        }

        newshowmodal(produit_id, is_not_produit);
    } else {

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = $("#couleurProd" + i);
    var tailles = $("#tailleProd" + i);
    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == "") {
        $("#sizeerror").slideDown();

        return false;
    } else if (couleurs && couleurs.val() == "") {
        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != "none") idCouleur = couleurs.val();

        if (tailles && tailles.val() != "none") idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(
            path_web +
            "ajax_upd_panier.php?prixu=" +
            prixU +
            "&idprod=" +
            idProduit +
            "&idcouleur=" +
            idCouleur +
            "&idtaille=" +
            idTaille +
            "&qte=" +
            qte +
            "&panierId=" +
            paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                        ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation +=
                        " - " +
                        $('[name="' + idElCouleur + '"]:checked')
                            .get(0)
                            .nextElementSibling.title.trim();
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(translate("product_no_stock_2"));
                return false;
            }
        }

        return array_response;
    }
}

/***** FUNCTION PUSH INTO DATALAYER  *****/

function pushIntoDatalayer(data) {
    if (typeof (data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof (data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

/*Page sliders*/
setTimeout(function () {
    if ($("#visual_scroller .swiper-slide").length > 1) {
        var productThumbs = new Swiper('#visual_scroller', {
            slidesPerView: 'auto',
            spaceBetween: 22,
            direction: 'vertical',
            freeMode: true,
            loop: false,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.thumbNext',
                prevEl: '.thumbPrev',
            },
        });
    } else {
        $("#visual_scroller_wrapper").addClass('no_swiper');
    }

    // PRODUCT SWIPER
    if ($("#jqzoom .swiper-slide").length > 1) {
        //animation to avoid slide's width transition on pageload
        $('#jqzoom').animate({
            opacity: 1,
        }, 10);

        new Swiper('#jqzoom', {
            loadOnTransitionStart: true,
            thumbs: {
                swiper: productThumbs
            },
            slidesPerView: 1,
            loop: false,
        });
    } else {
        $('#jqzoom').animate({
            opacity: 1,
        }, 10);
    }

    if ($("#productVisitedSwiper .swiper-slide").length) {
        var productVisitedSwiper = new Swiper('#productVisitedSwiper', {
            slidesPerView: 3,
            spaceBetween: 40,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    } else {
        $("#productVisitedSwiper").addClass('no_swiper');
    }

    if ($("#gondole_complete .swiper-slide").length > 3) {
        var gondole_complete_swiper = new Swiper('#gondole_complete', {
            slidesPerView: 3,
            spaceBetween: 40,
            //centeredSlides: true,
            loop: false,
            navigation: {
                nextEl: '.gondole_complete_btn.swiper-button-next',
                prevEl: '.gondole_complete_btn.swiper-button-prev',
            },
            pagination: {
                el: '.gondole_complete_pagination ',
                clickable: true,
                type: 'fraction',
            },
        });
    } else {
        $("#gondole_complete, .gondole_complete_btn").addClass('no_swiper');
    }

    if ($("#gondole_aimerez .swiper-slide").length > 4) {
        var gondole_aimerez = new Swiper('#gondole_aimerez', {
            slidesPerView: 4,
            spaceBetween: 40,
            loop: true,
            navigation: {
                nextEl: '.gondole_aimerez_btn.swiper-button-next',
                prevEl: '.gondole_aimerez_btn.swiper-button-prev',
            },
            pagination: {
                el: '.gondole_aimerez_pagination ',
                clickable: true,
                type: 'fraction',
            },
        });
    } else {
        $("#gondole_aimerez, .gondole_aimerez_btn").addClass('no_swiper');
    }

    if ($("#thumbs_scroller .swiper-slide").length > 1) {
        var zoomThumbs = new Swiper('#thumbs_scroller', {
            slidesPerView: 'auto',
            spaceBetween: 24,
            direction: "vertical",
            loop: true,
            navigation: {
                nextEl: '.thumbsNext',
                prevEl: '.thumbsPrev',
            },
        });
    } else {
        $("#thumbs_scrolle, .thumb_wrapper").addClass('no_swiper')
    }

    if ($("#zoom_box_wrapper .swiper-slide").length > 1) {
        var main_productVisu = new Swiper('#zoom_box_wrapper', {
            slidesPerView: 1,
            mousewheel: true,
            thumbs: {
                swiper: zoomThumbs
            },
            preloadImages: false,
            lazy: true,
            loadPrevNext: true,
            loadOnTransitionStart: true
        });
    } else {
        $("#zoom_box_wrapper").addClass('no_swiper')
    }

    /*COLORIS FP*/
    if ($("#colorsSwiperFp .swiper-slide").length > 1) {
        var coloris_fp = new Swiper('#colorsSwiperFp', {
            slidesPerView: 12,
            spaceBetween: 24,
            loop: false,
            slidesOffsetAfter: 20,
            navigation: {
                nextEl: '.coloris_next',
                prevEl: '.coloris_prev',
            },
            breakpoints: {
                1024: {
                  spaceBetween: 16,
                },
                1540: {
                  spaceBetween: 24,
                }
            }
        });
    } else {
        $("#colorsSwiperFp").addClass('no_swiper')
    }

    $('#productVisitedSwiper').css('opacity', '1');
    $('#gondole_aimerez').css('opacity', '1');
    $('#gondole_complete').css('opacity', '1');

}, 1000);

$(document).on("click", ".thumblnk", function (evt) {
    var id_pannel = this.getAttribute("data-pannel");
    var thumbnav = $(this).closest(".thumbnav");
    var thumbcnt = thumbnav.next(".thumbcnt");

    evt.preventDefault();

    thumbnav.find(".thumblnk").not(".thumblnk[data-pannel=" + id_pannel + "]").removeClass("current");
    thumbcnt.find(".thumbslide").not(".thumbslide[data-pannel=" + id_pannel + "]").removeClass("current");
    thumbnav.find(".thumblnk[data-pannel=" + id_pannel + "]").addClass("current");
    thumbcnt.find(".thumbslide[data-pannel=" + id_pannel + "]").addClass("current");
});


$(function () {
    if ($('.thumbnav [data-pannel="description"]').length > 0) {
        //Init first tab
        loadReadMoreButton('#description .detail_content', '.see_more', 10, '.thumbcnt');

        $('.thumbnav [data-pannel="description"]').click(function () {
            if (!$('#description').hasClass('clicked')) {
                loadReadMoreButton('#description .detail_content', '#description .see_more', 10, '.thumbcnt');
            }
        });
    }

    if ($('.thumbnav [data-pannel="informtations"]').length > 0) {
        $('.thumbnav [data-pannel="informtations"]').click(function () {
            if (!$('#informtations').hasClass('clicked')) {
                loadReadMoreButton('#informtations .detail_content', '#informtations .see_more', 10, '.thumbcnt');
            }
        });
    }

    if ($('.thumbnav [data-pannel="livraison_retour"]').length > 0) {
        $('.thumbnav [data-pannel="livraison_retour"]').click(function () {
            if (!$('#livraison_retour').hasClass('clicked')) {
                loadReadMoreButton('#livraison_retour .carac_container', '#livraison_retour .see_more', 10, '.thumbcnt');
            }
        });
    }
});

function loadReadMoreButton(element, button, show_lines, wrapper) {
    if ($(element).length > 0) {
        $(wrapper).css("opacity", "0");
        $(element).css("height", "auto");

        window.setTimeout(function () {
            var no_of_lines = countLinesInElement(element, show_lines);

            if (no_of_lines > show_lines) {
                $(element).css("overflow", "hidden");
                $(button).css("display", "inline-block");
            }
            else {
                $(button).css("display", "none");
                $(element).css("height", "auto");
            }

            $(wrapper).css("opacity", "1");
        }, 200);
    }
}

function countLinesInElement(element, show_lines) {
    var el = document.querySelector(element);
    var el_height = el.offsetHeight;
    var el_style = window.getComputedStyle(el);
    var el_line_height = parseInt(el_style.getPropertyValue('line-height'), 10);
    el_line_height = (el_line_height > el_height) ? el_height : el_line_height;
    el.style.height = (el_line_height * show_lines) + "px";
    return el_height / el_line_height;
}

// Alters item quantity in cart
function changeQte(type) {

    var types = ['plus', 'less'],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'block';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                // Cart slider initialization
                var sliderCart = $('#main_cart_wrapper #basketAssocWrapper');

                if (sliderCart.length) {

                    sliderCart.remove();
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;

                var $label_basket_line_qte = Translator.translate('quantity');
                $("<label>" + $label_basket_line_qte + "</label>").insertBefore(".product_quantity_change .qteWrapper");
            },
            complete: function () {

                var total = $('#cart_top .link_item_cart').attr('data-cart'),
                    cart = translate('mon_panier'),
                    text;
                if (total <= 1) {
                    text = translate('one_product');
                } else {
                    text = translate('2_product');
                };
                $('.cart_main_title.basket').html(cart + ' ' + '<span class="nb_products">' + total + ' ' + text + '</span>');

                // Update Cart elements after ajax reload
                updateCartElements();
            }
        });
    }
}

/**
 * Tunnel FAQ
 */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

function newshowmodal(products, type, root, id_produit, is_not_produit) {
    toggleRolloverLinkItem();
    topCartAppear();

    window.setTimeout(function () {

        topCartDesappear();
    }, 2000);
}

function topCartAppear() {
    var show_top_cart_parent = $('#cart_top');
    var count_bask = $('#cart_top .cart').attr('data-cart');

    $('#cart_top .cart_product_listing').overlayScrollbars({});
    if (count_bask > 2) {
    }

    show_top_cart_parent.addClass("hover");
    $('#shad_menu').addClass("actif");
}

function topCartDesappear() {
    var show_top_cart_parent = $('#cart_top');

    show_top_cart_parent.removeClass("hover");
    $('#shad_menu').removeClass("actif");
    $('#top_wrapper').removeClass('has_shad');
}

/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

$.fn.selectChangeAdr = function () {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );
    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }
        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }
        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + "livraison-ajax.php",
            success: function (response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);
                var all_form;

                $changeContainer
                    .html($form.html(min_form))
                    .css({ display: "none" })
                    .slideDown();
                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.log(e);
                }
                initialize_google_place("delivery");
                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function () {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function () {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }
            },
        });
    }
};

function moveProductFromWishlistToCart(array_wishlist_product_ids) {
    $.post(path_relative_root + "ajax_add_panier_wishlist.php", { data: array_wishlist_product_ids.join(",") }, function (resp) {
        if (resp == "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function () {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    // Simpler way doesn't seem to work well with lazyloader
    // return document.getElementById(field).src = path;

    var productSwiper = new Swiper(".productSwiper", {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.rollover_rayonsNext',
            prevEl: '.rollover_rayonsPrev',
        },
        preloadImages: false,
        lazy: true,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });
}

function closeAlerteStock(elem) {

    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();

    // Show bloc colors
    $('#ligne_couleur_' + elem).show();

    // Hide bloc size
    $('#ligne_pointure_' + elem).hide();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
    $('.wishlistBtn').show();
    $('.bloc_add_color').show();
}

/*
 * Permet l'envoi du mail qui confirme l'enregistrement de sont email, pour alerter la personne quand le produit sera disponible
 */
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {

        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {

        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function (response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail: function () {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );
                is_disabled = !sizes_tab[size_id];
                if (is_disabled) {
                    size_box.addClass('disabled');
                }
                else {
                    size_box.removeClass('disabled');
                }
            }
        }
    }
}

function btnLoaderUnloading(btn) {

    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {

        setTimeout(function () { loader.removeAttr("style").parent().removeClass("loading") }, 200);
    }
}

// FERMER EN SAVOIR PLUS CONNEXION

function close_popup(elem) {
    var $modbox = $("#" + elem);
    $("#" + elem).removeClass('actif');
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({ opacity: 0 }, 350, function () {
        $shad.css({ display: "none", opacity: "" }).data("opened_lb", 0);
    });
    $modbox.css({ display: "none" });
}

/*********/
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subPassword.animate({
        opacity: 0
    }, 600, function () {

        subPassword.css('display', 'none');

        subLogin.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                        fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}


// JOB DESCRIPTION SLIDER
$(function () {

    var $sblock = $('.cms_page_content .module_wrapper .cms-page-columns-container');

    $sblock.each(function (i, element) {
        var clone = $(element).find('.offer_container');
        clone.html();
        $(element).append('<div id="content_clone_' + i + '" class="content_offers">' + clone.html() + '</div>');
        $('.content_offers').css('display', 'none');
        $('.module_wrapper').wrapAll('<div class="module_wrapper_container" />');
        $('.module_wrapper_container').css({
            'width': '100%',
            'position': 'relative'
        });

        $(this).on("click", function (e) {

            if ($sblock.not($(this)).hasClass("active")) {
                $sblock.not($(this)).removeClass("active");
                $sblock.not($(this)).find(".content_offers").fadeOut("slow");
            }

            if (!$(this).hasClass("active")) {
                $(this).addClass("active");
                $(this).find(".content_offers").fadeIn("slow");

                // remonte le contenu de l'annonce au clic sur le titre de l'annonce
                var x = $(".module_wrapper").offset();
                $('html, body').animate({ scrollTop: x.top - 100 }, 'slow');
            } else {
                $(this).removeClass("active");
                $(this).find(".content_offers").fadeOut("slow");
            }
        });
    });

    //change selected offer bg color
    $(function () {
        var article = $('.cms_page_content .module_wrapper .cms-page-columns-container .cms-page-column');
        article.each(function () {
            $(this).on('click', function (e) {
                // réinitialise en enlevant la classe partout
                article.removeClass('selected');
                // ajoute la classe au click de l'élément
                $(this).addClass('selected');
            });
        });
    });

    // gère la propagation des event au clic
    $(".content_offers").on("click", function (e) {
        e.stopPropagation();
    });
    //ancre remonte en haut au clic du btn postuler dans content_offers
    $sblock.find('.btn_offer').on('click', function () {
        $('html, body').animate({ scrollTop: 200 }, 'slow');
    });

});



